let BaseUrl = {};
switch (process.env.NODE_ENV) {
  case "development":
    BaseUrl = {
      dev: "https://paramountecom.pythonanywhere.com",
    };
    break;
  case "production":
    BaseUrl = {
      dev: "https://paramountecom.pythonanywhere.com",
    };
    break;
  default:
    BaseUrl = {
      dev: "https://paramountecom.pythonanywhere.com",
    };
    break;
}

export default BaseUrl;
