import { IMAGES } from "../Images";
import "./Loader.css";

const Loader = () => {
  return (
    <div>
      <div className="loader">
        <img src={IMAGES.LOADERLOGO} className="central-icon" alt="Food Loader" />
      </div>
      {/* <div className="loader-content">Please wait while we load up things for you.</div>     */}
    </div>
  );
};

export default Loader;
