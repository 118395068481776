const initialState = {
  products: [],
  totalWeight: 0,
  cartTotal: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD":
      const item =
        state.products && state.products.length > 0
          ? [...state.products, action.payload.products]
          : [action.payload.products];
      return {
        ...state,
        products: item,
      };
    case "HYDRATE":
      return {
        ...state,
        ...(action.payload.products !== undefined && {
          products: action.payload.products,
        }),
        ...(action.payload.totalWeight !== undefined && {
          totalWeight: action.payload.totalWeight,
        }),
        ...(action.payload.cartTotal !== undefined && {
          cartTotal: action.payload.cartTotal,
        }),
      };
    case "EMPTY":
      return {
        ...state,
        products: [],
        totalWeight: 0,
        cartTotal: 0,
      };

    default:
      return state;
  }
};

export default cartReducer;
